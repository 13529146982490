<template>
  <div class="lead-directory">
    <h2 class="section-header">
      <img src="@/assets/images/folder-icon.svg" alt="lead directory" />
      Lead Directory
    </h2>

    <div class="top-cards-holder">
      <div class="row">
        <div class="col-md-4">
          <div class="top-card first-card">
            <p>Number of direct recruits</p>
            <h2>{{ directRecruit }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="top-card second-card">
            <p>Number of indirect recruits</p>
            <h2>{{ inDirectRecruit }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="top-card third-card">
            <p>Number Properties</p>
            <h2>{{ propertyCount }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="lead-property-holder">
      <div class="nav-btn">
        <button class="btn" :class="[tableType == 'lead' ? 'active-table' : '']" @click="tableType = 'lead'">Lead Log</button>
        <button class="btn" :class="[tableType == 'property' ? 'active-table' : '']" @click="tableType = 'property'">Landlord Log</button>
      </div>


      <div class="table-responsive table-holder" v-if="tableType === 'lead'">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Referal</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lead, index) of leadLog" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <th>{{ lead.firstname }} {{ lead.lastname }}</th>
              <td>{{ lead.email }}</td>
              <td>{{ lead.phone }}</td>
              <td>{{ lead.referalLink }}</td>
              <td><span class="status-o" :class="[lead.smartsubscribed == 'YES' ? 'status-o' : 'status-v']">{{ lead.smartsubscribed == 'YES' ? 'Subscribed' : 'Unsubscribed' }}</span></td>
            </tr>            
          </tbody>
        </table>
        <div
          class="empty text-center m-5"
          v-if="leadLog.length === 0 && fetchData2 === 'loaded'"
        >
          No Records
        </div>
      </div>
      <div class="table-responsive table-holder" v-if="tableType === 'property'">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th scope="col">Landlord</th>
              <th scope="col">User ID</th>
              <th scope="col">Units</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="propertyLog.length > 0">
            <tr v-for="(landlord, index) of propertyLog"
              :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <th>{{ landlord.firstname }} {{ landlord.lastname }}</th>
              <td>{{ landlord.usercode }}</td>
              <td>{{ landlord.propertiesCount }}</td>
              <!-- status-v -->
              <td><span class="status-o">Occupied</span></td>
              <td class="detail-text" data-toggle="modal" data-target="#propdetails" @click="viewPropertyDetails(landlord.id)">Details</td>
            </tr>          
          </tbody>
        </table>
        <div
          class="empty text-center m-5"
          v-if="propertyLog.length === 0 && fetchData3 === 'loaded'"
        >
          No Records
        </div>
      </div>

      <!-- <div class="loading-container text-center" v-if="fetchData2 === 'loaded'">
          <Loader />
      </div> -->
      <div class="loading-container text-center" v-if="fetchData3 === 'loading'">
          <Loader />
      </div>
      
      

    </div>

    <div class="modal fade" tabindex="-1" id="propdetails">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Property Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="loading-container text-center" v-if="propDetaialsLoader">
                        <Loader />
                    </div>
                    <div v-else class="modal-body">
                      <!-- <p>{{ propItem }}</p> -->
                       <div class="row">
                        <div class="col-md-6" v-if="propItem">
                         <div class="prop-init-image">
                          <img v-if="propItem.images.length != 0" class="w-100" :src="propItem.images[0]" alt="image">
                          <img v-else class="w-100" src="../assets/images/ph-image.svg" alt="image">
                         </div>
                        </div>
                        <div class="col-md-6">
                          <div class="propdetail-holder" v-if="propItem">
                            <h6 class="prop-title">Title: {{propItem.title}}</h6>
                            <h6>Location: {{propItem.city}}, {{ propItem.state }}</h6>
                            <h6>Pin: {{propItem.pin}}</h6>
                            <h6>Rate: ₦{{ Number(propItem.price).toLocaleString() }}</h6>
                            <!-- <h6>Commission: ₦50,000</h6> -->
                            <h6>Description: {{ propItem.description }}</h6>
                          </div>
                        </div>
                       </div>
                    </div>

                    <div class="page-navigation" v-if="propertiesLandlord.length > 1">
                        <button @click="prevBtnProp()" id="prev-button" class="nav-btn" :disabled="currentPropIndex === 0">Prev</button>
                        <div class="page-btn">
                          <button v-if="propertiesLandlord.length > 1" class="btn" :class="[currentPropIndex == 1 ? 'pagenumActive': '']" :disabled="currentPropIndex == 1" @click="numberIndex(1)">1</button>
                          <button v-if="propertiesLandlord.length > 2" class="btn" :class="[currentPropIndex == 2 ? 'pagenumActive': '']" :disabled="currentPropIndex == 2" @click="numberIndex(2)">2</button>
                          <button v-if="propertiesLandlord.length > 3" class="btn" :class="[currentPropIndex == 3 ? 'pagenumActive': '']" :disabled="currentPropIndex == 3" @click="numberIndex(3)">3</button>
                          <!-- <button class="btn disabled">...</button> -->
                        </div>
                        <button @click="nextBtnProp()" class="nav-btn" id="next-button" :disabled="currentPropIndex === propertiesLandlord.length - 1">Next</button>
                    </div>

                    <div
                      class="empty text-center m-5"
                      v-if="propertiesLandlord.length === 0 && !propDetaialsLoader"
                    >
                      No property yet!
                    </div>
               
                </div>
            </div>
    </div>

    <!-- <CardsOverflow>
      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/big-white-arrow.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ directRecruit }}</div>
            <div class="text">Number of direct recruits</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-details">
          <div class="image-container" style="rotate: 180deg;">
            <img src="@/assets/images/big-white-arrow.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ inDirectRecruit }}</div>
            <div class="text">Number of indirect recruits</div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-details">
          <div class="image-container" style="rotate: 180deg;">
            <img src="@/assets/images/properties.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ propertyCount }}</div>
            <div class="text">Number of properties</div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/logs.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ points }}</div>
            <div class="text">Points</div>
          </div>
        </div>
      </div>
    </CardsOverflow> -->
  </div>
</template>

<script>
// import CardsOverflow from "./Layout/CardsOverflow";
import directory from "../services/directory";
import Loader from "./Loader";

export default {
  name: "LeadDirectory",
  components: {
    // CardsOverflow,
    Loader
  },
  data() {
    return {
      propDetaialsLoader: false,
      directRecruit: 0,
      inDirectRecruit: 0,
      propertyCount: 0,
      points: 0,
      leadLog: [],
      propertyLog: [],
      landlordProperties: [],
      propertiesLandlord: [],
      fetchData: "",
      fetchData2: "",
      fetchData3: "",
      tableType: "lead",
      currentPropIndex: 0,
      // leadLogHeight: "fit-content",
      // leadLogHeightStore: 0,
      // leadLogHeaderHeight: 0,

      // propertiesLogHeight: "fit-content",
      // propertiesLogHeightStore: 0,
      // propertiesLogHeaderHeight: 0
    };
  },
  computed: {
    propItem() {
      return this.propertiesLandlord[this.currentPropIndex];
    }
  },
  methods: {
    // toggleList() {
    //   const tableContainer = document.querySelector(
    //     ".lead-log .table-container"
    //   );

    //   tableContainer.classList.toggle("hide");

    //   if (this.leadLogHeight === this.leadLogHeaderHeight) {
    //     this.leadLogHeight = this.leadLogHeightStore;
    //   } else {
    //     this.leadLogHeight = this.leadLogHeaderHeight;
    //   }
    // },
    // togglePropertiesList() {
    //   const tableContainer = document.querySelector(
    //     ".properties-log .table-container"
    //   );

    //   tableContainer.classList.toggle("hide");

    //   if (this.propertiesLogHeight === this.propertiesLogHeaderHeight) {
    //     this.propertiesLogHeight = this.propertiesLogHeightStore;
    //   } else {
    //     this.propertiesLogHeight = this.propertiesLogHeaderHeight;
    //   }
    // },
    // updateButtonStates() {
    //   document.getElementById('prev-button').disabled = this.currentPropIndex === 0;
    //   document.getElementById('next-button').disabled = this.currentPropIndex === data.length - 1;
    // },
    prevBtnProp() {
      if (this.currentPropIndex > 0) {
        this.currentPropIndex--;
      }
    },
    nextBtnProp() {
      if (this.currentPropIndex < this.propertiesLandlord.length - 1) {
        this.currentPropIndex++;
      }
    },
    numberIndex(index) {
      this.currentPropIndex = index;
    },

    viewPropertyDetails(id) {
      this.propDetaialsLoader = true;
      directory
        .landlordProperties(id)
        .then(data => {
          this.propDetaialsLoader = false;
          if (data.success) {
            this.propertiesLandlord = data.properties;
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch((err) => {
          this.propDetaialsLoader = false;
          console.log(err);
          // this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
          this.propDetaialsLoader = false;
          this.fetchData = "loaded";
        });
    },
    universalFormat(value) {
      return value.toLocaleString();
    }
  },
  created() {
    const userID = this.$store.getters.getUser.id;
    directory
      .directRecruits(userID)
      .then(data => {
        if (data.success) {
          // TODO: this api return direct, indirect and property, it doesnt return points yet
          this.directRecruit = data.direct;
          this.inDirectRecruit = data.indirect;
          this.propertyCount = data.property;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        // this.$toast.error("please check your network and refresh the page");
      });

    this.fetchData2 = "loading";
    directory
      .leadLog(userID)
      .then(data => {
        if (data.success) {
          this.leadLog = data.leads;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        // this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData2 = "loaded";
      });

    this.fetchData3 = "loading";
    directory
      .propertyLog(userID)
      .then(data => {
        if (data.success) {
          this.propertyLog = data.leadswithproperty;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        // this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData3 = "loaded";
      });
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
/* @import "../styles/section-header.css";
@import "../styles/table.css"; */

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;


.top-cards-holder {
  box-shadow: 0px 1px 2px 0px #0000000F;
  box-shadow: 0px 1px 3px 0px #0000001A;

  border-radius: 20px;
  padding: 24px;
  background: $white;

}
.top-card {
  padding: 16px;
  border-radius: 10px;
  min-height: 159px;

  p {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: $asheGrey;
  }
  h2 {
    font-family: Lato;
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    color: $asheGrey;
    margin-top: 10px;
  }
}

.first-card {
  background: #F5F7FE;
}
.second-card { 
  background: #F1ECFE;
}
.third-card {
  background: #FFF7E6;
}

.lead-property-holder {
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;
  border-radius: 8px;
  background: $white;
  margin-top: 20px;
}

.nav-btn {
  padding: 20px 30px;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: center;
    color: #57595A;
    outline: 0;
    box-shadow: none;
  }
}

.active-table {
  background: #FAFBFF;
  color: $primary !important;
  border-radius: 8px;
}

// Table section 

.table-holder {
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;
  border: 1px solid #EAECF0;
  border-radius: 8px;
}
.table thead th {
  border-bottom: none;
}

thead {
  background: #F9FAFB;
  tr {
    // border: 1px solid #EAECF0;
    // border-top-left-radius: 10px !important;
    // background: #F9FAFB;

    th {
      font-family: Lato;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: #667085;
    }
  }
}

tbody {
  tr {
    td, th {
      border-top: 1px solid #EAECF0;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $asheGrey;
      padding: 22px 10px;
    }
  }
}

.detail-text {
  color: $primary;
  cursor: pointer;
}

.status-o {
  background: #ECFDF3;
  color: #2BC300;
  padding: 5px 10px;
  border-radius: 50px;
  text-transform: capitalize;
}
.status-v {
  background: #FEF3F2;
  color: #C3002B;
  padding: 5px 10px;
  border-radius: 50px;
  text-transform: capitalize;
}


.prop-init-image {
  img {
    object-fit: cover;
    border-radius: 10px;
    max-height: 180px;
  }
}
.propdetail-holder {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: $asheGrey;
  }
}

.prop-title {
  width: 220px; /* Set a width for the container */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) to the hidden text */
}

// Pagination css starts here
.page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  .nav-btn {
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.7px;
    text-align: left;
    color: $asheGrey;
    padding: 0 10px;
    background: none;
  }
}

.page-btn {
  margin: 20px 0;
  button {
    border: 1px solid #f1f1f1;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.7px;
    text-align: left;
    color: $asheGrey;
    margin: 0 5px;
    box-shadow: none;
    outline: 0;
  }
  button:nth-child(4) {
    border: none;
  }
}

.pagenumActive {
  background: $primary;
  color: $white !important;
  border: 1px solid $primary;
}

.disabled-span {
  color: #CCCED5 !important;
}

.disabled-span:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
  color: #CCCED5 !important;
}
button:disabled {
  color: #CCCED5 !important;
}


// Pagination css ends here
@media screen and (max-width: 599px) {
  .top-card {
   margin-bottom: 10px;
   h2 {
    font-size: 35px;
   }
  }
  .prop-init-image {
    margin-bottom: 10px;
  }
  .prop-title {
    width: 306px;
  }
}

</style>
